import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export async function login(username, password) {
  const API = process.env.REACT_APP_API;
  try {
    const response = await fetch(`${API}/admin/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    });

    const data = await response.json();

    if (response.ok) {
      if (data.token) {
        localStorage.setItem('token', data.token);
        return data;
      } else {
        throw new Error('Unauthorized');
      }
    } else {
      throw new Error('Login failed. Please check your internet or credentials.');
    }
  } catch (err) {
    throw err;
  }
}

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await login(username, password);
      navigate('/dashboard');
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    }finally {
      setIsLoading(false);
    }
  };

  const isButtonDisabled = !username.includes('c-') || isLoading;

  return (
    <div className="bg-dark text-white min-vh-100 d-flex py-5">
      <Container>
        <div className='d-flex justify-content-center mb-2'>
          <img src='https://i.ibb.co/2565KrZ/images-removebg-preview.png' height={35} width={35}></img>
          <h3 className="text-white text-center mx-2">Champion Bingo</h3>
        </div>
        <Row className="justify-content-center py-5" style={{ backgroundColor: 'black', maxWidth: '40%', margin: 'auto'}}>
          <Col md={10}>
            <Form onSubmit={handleSubmit}>
            <h4>Sign in to your account</h4>
              <Form.Group controlId="username" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group controlId="password" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <div className="d-grid">
                <Button variant="primary" type="submit" disabled={isLoading || isButtonDisabled}>
                  {isLoading ? 'Signing in...' : 'Sign in'}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </div>
  );
}

export default Login;