import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { FaGamepad } from 'react-icons/fa';

function AdminGames() {
  const [games, setGames] = useState([]);
  const API = process.env.REACT_APP_API;
  const [stats, setStats] = useState({
    statsByPeriod: [],
    totalProfit: 0,
    totalBetAmount: 0,
    totalGames: 0
  });

  useEffect(() => {
    const fetchStats = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API}/admin/stats`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const data = await response.json();
      setStats(data);
    };
    fetchStats();
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API}/admin/myGames`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const data = await response.json();
        setGames(data);
      } catch(error) {
        console.log(error);
      }
    }
    fetchGames();
  }, []);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const dayOfWeek = days[date.getDay()];
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
    return `${dayOfWeek}, ${month} ${day}, ${year} ${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  return (
    <div className="my-3 m-4 p-3 text-white">
      <h2 className='text-white'>All Information</h2>
      <div className='d-flex'>
        <div className="d-flex align-items-center p-3" style={{minHeight: '75px', backgroundColor: '#000', marginRight: 100, borderRadius: '16px'}}>
          <span className="text-white fs-5">Remaining Coins: {stats.wallet} Birr</span>
        </div>
      </div>
      <h4 className='text-white mt-5 text-dark text-center'>Games</h4>
      <Table bordered hover variant="dark">
        <thead>
          <tr>
            <th style={{backgroundColor: 'black'}} className='text-white'>Date</th>
            <th style={{backgroundColor: 'black'}} className='text-white'>Bet Amount</th>
            <th style={{backgroundColor: 'black'}} className='text-white'>Players</th>
            <th style={{backgroundColor: 'black'}} className='text-white'>Status</th>
            <th style={{backgroundColor: 'black'}} className='text-white'>Total Bet Amount</th>
            <th style={{backgroundColor: 'black'}} className='text-white'>Profit</th>
          </tr>
        </thead>
        <tbody className='text-white'>
          {games.map(game => (
            <tr key={game.id}>
              <td style={{backgroundColor: 'black'}}>{formatDate(game.date)}</td>
              <td style={{backgroundColor: 'black'}}>{game.bettingAmount} Birr</td>
              <td style={{backgroundColor: 'black'}}>{game.players}</td>
              <td style={{backgroundColor: 'black'}}>{game.status}</td>
              <td style={{backgroundColor: 'black'}}>{game.bettingAmount * game.players} Birr</td>
              <td style={{backgroundColor: 'black'}}>{game.bettingAmount * game.players * 0.2} Birr</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default AdminGames;