import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AdminStats from './Stats';
import AllCards from './AllCards';
import AdminGames from './myGames';

const Report = () => {
  const [activeComponent, setActiveComponent] = useState('AdminGames');
  const navigate = useNavigate(); // Initialize the navigate function
 
  const renderComponent = () => {
    switch (activeComponent) {
      case 'AdminStats':
        return <AdminStats />;
      case 'AllCards':
        return <AllCards />;
      case 'AdminGames':
        return <AdminGames />;
      default:
        return null;
    }
  };

  const handleNavClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const handleBack = () => {
    navigate('/dashboard'); // Use navigate to go back to the dashboard
  };

  return (
    <div style={{ display: 'flex', minHeight: '100vh', backgroundColor: '#222222' }}>
      <div
        style={{
          width: '200px',
          backgroundColor: '#333',
          color: '#fff',
          padding: '20px',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '5px',
        }}
        className='mx-5 my-5 rounded-3'
      >
        <h2 style={{ fontSize: '24px', marginBottom: '10px' }}>Admin Page</h2>
        <div className='d-flex flex-column mt-4'>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li style={{ marginBottom: '20px' }}>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => handleNavClick('AdminGames')}
              >
                All Games
              </button>
            </li>
            <li>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => handleNavClick('AdminStats')}
              >
                Daily Reports
              </button>
            </li>
          </ul>
        </div>
        <button className="btn btn-light" onClick={handleBack}>Back</button> {/* Use handleBack to navigate back */}
      </div>
      <div style={{ marginLeft: '220px', padding: '20px', width: 'calc(100% - 220px)' }}>
        {renderComponent()}
      </div>
    </div>
  );
};

export default Report;
