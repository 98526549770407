import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, Form } from 'react-bootstrap';

const AdminTable = () => {
  const [admins, setAdmins] = useState([]);
  const [editingAdminId, setEditingAdminId] = useState(null);
  const [addAmount, setAddAmount] = useState('');
  const [showRegistration, setShowRegistration] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [initialWallet, setInitialWallet] = useState('');
  const [lastFiftyGames, setLastFiftyGames] = useState([]);
  const [showLastFiftyGames, setShowLastFiftyGames] = useState(false);
  const [lastTenGamesUsername, setLastTenGamesUsername] = useState('');

  const API = process.env.REACT_APP_API;

  useEffect(() => {
    fetchAdmins();
  }, []);

  const handleRefresh = () => {
    fetchAdmins();
  };

  const [lastTenGames, setLastTenGames] = useState([]);

  const fetchLastTenGames = async (adminId, adminUsername) => {
    try {
      const response = await axios.get(`${API}/admin/${adminId}/last-games`);
      setLastTenGames(response.data);
      setLastTenGamesUsername(adminUsername);
    } catch (error) {
      console.error('Error fetching last 10 games:', error);
    }
  };

  const fetchLastFiftyGames = async () => {
    try {
      const response = await axios.get(`${API}/admin/last-games`);
      setLastFiftyGames(response.data);
      setShowLastFiftyGames(true);
    } catch (error) {
      console.error('Error fetching last 50 games:', error);
    }
  };

  const handleRegistration = async (event) => {
    event.preventDefault();
    try {
      await axios.post(`${API}/admin/signup/protected/naan`, { username, password, initialWallet });
      alert('User registered successfully');
      setShowRegistration(false);
      setUsername('');
      setPassword('');
      setInitialWallet('');
    } catch (error) {
      console.error('Error registering user:', error);
      alert('Failed to register user');
    }
  };


  const fetchAdmins = async () => {
    try {
      const response = await axios.get(`${API}/admin/naan/all/protected`);
      setAdmins(response.data);
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const handleEditWallet = (adminId) => {
    setEditingAdminId(adminId);
    setAddAmount('');
  };

  const handleAddAmountChange = (event) => {
    setAddAmount(event.target.value);
  };

  const addToWallet = async (adminId) => {
    try {
      const currentAdmin = admins.find((admin) => admin._id === adminId);
      const updatedWallet = currentAdmin.wallet + parseFloat(addAmount);
      await axios.put(`${API}/admin/${adminId}/wallet`, { wallet: updatedWallet });
      setAdmins(
        admins.map((admin) => (admin._id === adminId ? { ...admin, wallet: updatedWallet } : admin))
      );
      setEditingAdminId(null);
      setAddAmount('');
    } catch (error) {
      console.error('Error updating admin wallet:', error);
    }
  };


  const getTodaysProfit = (admin) => {
    const today = new Date().toISOString().slice(0, 10);
    const todayStat = admin.statsByPeriod.find((stat) => stat.date.startsWith(today));
    return todayStat ? todayStat.profit : 0;
  };

  const getThisWeekAverage = (admin) => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

    const thisWeekStats = admin.statsByPeriod.filter(
      (stat) => new Date(stat.date) >= startOfWeek && new Date(stat.date) <= endOfWeek
    );

    const totalProfit = thisWeekStats.reduce((sum, stat) => sum + stat.profit, 0);
    const averageProfit = totalProfit / thisWeekStats.length;

    return isNaN(averageProfit) ? 0 : averageProfit.toFixed(2);
  };

  const getThisWeekProfit = (admin) => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

    const thisWeekStats = admin.statsByPeriod.filter(
      (stat) => new Date(stat.date) >= startOfWeek && new Date(stat.date) <= endOfWeek
    );

    const totalProfit = thisWeekStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit;
  };


  const getMaxProfit = (admin) => {
    const maxProfit = Math.max(...admin.statsByPeriod.map((stat) => stat.profit));
    return isFinite(maxProfit) ? maxProfit : 0;
  };

  const getTodaysTotalProfit = () => {
    return admins.reduce((total, admin) => total + getTodaysProfit(admin), 0);
  };

  const getThisWeekTotalProfit = () => {
    return admins.reduce((total, admin) => total + getThisWeekProfit(admin), 0);
  };

  const getMonthlyProfit = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const monthlyStats = admins.flatMap((admin) =>
      admin.statsByPeriod.filter((stat) => {
        const statDate = new Date(stat.date);
        return statDate.getMonth() === currentMonth && statDate.getFullYear() === currentYear;
      })
    );

    const totalProfit = monthlyStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit;
  };

  const getTotalProfitToDate = () => {
    const totalProfit = admins.reduce((sum, admin) => sum + admin.totalProfit, 0);
    return totalProfit;
  };


  return (
    <div className="container">
      <div className='d-flex'>
        <h2 className="my-2">Admin Dashboard</h2>
        <Button variant="primary" onClick={handleRefresh} className="my-4 mx-2">
          Refresh Data
        </Button>
        <Button variant="primary" onClick={() => setShowRegistration(true)} className="my-4" style={{maxHeight: '65px', marginLeft: '60%' }}>
          Register User
        </Button>
        <Button variant="dark" onClick={fetchLastFiftyGames} className="my-4">
          Last 50 Games
        </Button>
      </div>

      <Modal show={showLastFiftyGames} onHide={() => setShowLastFiftyGames(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Last 50 Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Admin</th>
                <th>Betting Amount</th>
                <th>Number of Players</th>
                <th>Winning Amount</th>
                <th>Profit</th>
                <th> Status</th>
              </tr>
            </thead>
            <tbody>
              {lastFiftyGames.map((game) => (
                <tr key={game._id}>
                  <td>{new Date(game.createdAt).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })} {new Date(game.createdAt).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  })}</td>
                  <td>{game.admin.username}</td>
                  <td>{game.bettingAmount}</td>
                  <td>{game.numberOfPlayers}</td>
                  <td>{game.payoutToWinner}</td>
                  <td>{game.bettingAmount * game.numberOfPlayers * 0.2}</td>
                  <td>{game.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>

      <Modal show={showRegistration} onHide={() => setShowRegistration(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleRegistration}>
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="initialWallet">
              <Form.Label>Initial Wallet</Form.Label>
              <Form.Control type="number" value={initialWallet} onChange={(e) => setInitialWallet(e.target.value)} required />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Register
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <div className='m-2'>
        <button className="btn btn-success">Today's Profit: {getTodaysTotalProfit() * 0.175}</button>
        <button className="btn btn-success mx-2">This Week's Total Profit: {getThisWeekTotalProfit() * 0.175}</button>
        <button className="btn btn-success">This month's Profit: {getMonthlyProfit() * 0.175}</button>
        <button className="btn btn-success mx-2">Total Profit to Date: {getTotalProfitToDate() * 0.175}</button>
      </div>
      <div className="m-2 d-flex">
        <button className="btn btn-dark">Today's Total Revenue: {getTodaysTotalProfit()}</button>
        <button className="btn btn-dark mx-2">This Week's Total Revenue: {getThisWeekTotalProfit()}</button>
        <button className="btn btn-dark">Monthly Revenue: {getMonthlyProfit()}</button>
        <button className="btn btn-dark mx-2">Total Revenue to Date: {getTotalProfitToDate()}</button>
      </div>

      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>Username</th>
            <th> Last Login</th>
            <th>Total Profit</th>
            <th>Wallet</th>
            <th>Stats by Period</th>
            <th>Today's Profit</th>
            <th>This Week's Average</th>
            <th>Max Profit to Date</th>
            <th>Last Games</th>
            <th>Actions</th>

          </tr>
        </thead>
        <tbody>
          {admins.map((admin) => (
            <tr key={admin._id}>
              <td>{admin.username}</td>
              <td>
                {admin.lastLoginTime ? (
                  <>
                    {new Date(admin.lastLoginTime).toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}{' '}
                    {new Date(admin.lastLoginTime).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    })}
                  </>
                ) : (
                  ''
                )}
              </td>

              <td>{admin.totalProfit}</td>
              <td>{admin.wallet}</td>
              <td>
                <details>
                  <summary className="btn btn-sm btn-info">View Stats</summary>
                  <table className="table table-bordered mt-2">
                    <thead>
                      <tr>
                        <th>Period</th>
                        <th>Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {admin.statsByPeriod.map((stat, index) => (
                        <tr key={index}>
                          <td>{new Date(stat.date).toLocaleDateString()}</td>
                          <td>{stat.profit}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </details>
              </td>
              <td>{getTodaysProfit(admin)}</td>
              <td>{getThisWeekAverage(admin)}</td>
              <td>{getMaxProfit(admin)}</td>
              <td>
                {editingAdminId === admin._id ? (
                  <div className="input-group mt-1">
                    <input
                      type="number"
                      value={addAmount}
                      onChange={handleAddAmountChange}
                      className="form-control"
                      placeholder="Add amount"
                    />
                    <button onClick={() => addToWallet(admin._id)} className="btn btn-primary">
                      Add
                    </button>
                    <button onClick={() => setEditingAdminId(null)} className="btn btn-secondary">
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button onClick={() => handleEditWallet(admin._id)} className="btn btn-warning btn-sm">
                    Edit
                  </button>
                )}
              </td>
              <td>
                <button
                  onClick={() => fetchLastTenGames(admin._id, admin.username)}
                  className="btn btn-info btn-sm"
                >
                  Last 10 Games
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        show={lastTenGames.length > 0}
        onHide={() => setLastTenGames([])}
        username={lastTenGames.length > 0 ? lastTenGames[0].admin.username : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title>Last 10 Games - {lastTenGamesUsername}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Betting Amount</th>
                <th>Number of Players</th>
                <th>Winning Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {lastTenGames.map((game) => (
                <tr key={game._id}>
                  <td>
                    {new Date(game.createdAt).toLocaleDateString('en-US', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}{' '}
                    {new Date(game.createdAt).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    })}
                  </td>
                  <td>{game.bettingAmount}</td>
                  <td>{game.numberOfPlayers}</td>
                  <td>{game.payoutToWinner}</td>
                  <td>{game.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminTable;