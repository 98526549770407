import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './DashBoard.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronDown } from 'react-icons/fa'; 

const DashboardPage = () => {
  const [bettingAmount, setBettingAmount] = useState(localStorage.getItem('bettingAmount') || '');
  const [audioLanguage, setAudioLanguage] = useState(localStorage.getItem('audioLanguage') || '');
  const [callSpeed, setCallSpeed] = useState(localStorage.getItem('callSpeed') || '');
  const [winningPattern, setWinningPattern] = useState(localStorage.getItem('winningPattern') || '');
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [slideshowTables, setSlideshowTables] = useState([]);
  const API = process.env.REACT_APP_API;
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);


  const winningPatterns = [
    [1, 0, 0, 0, 0, 6, 0, 0, 0, 0, 10, 0, 12, 0, 0, 15, 0, 0, 0, 0, 21, 0, 0, 0, 0],
    [0, 2, 0, 0, 0, 0, 7, 0, 0, 0, 0, 11, 12, 0, 0, 0, 17, 0, 0, 0, 0, 22, 0, 0, 0],
    [0, 0, 3, 0, 0, 0, 0, 8, 0, 0, 0, 0, 12, 0, 0, 0, 0, 18, 0, 0, 0, 0, 23, 0, 0],
    [0, 0, 0, 4, 0, 0, 0, 0, 9, 0, 0, 0, 12, 13, 0, 0, 0, 0, 19, 0, 0, 0, 0, 24, 0],
    [0, 0, 0, 0, 5, 0, 0, 0, 0, 10, 0, 0, 12, 0, 14, 0, 0, 0, 0, 20, 0, 0, 0, 0, 25],
    
    [1, 2, 3, 4, 5, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 6, 7, 8, 9, 10, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 13, 12, 14, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 16, 17, 18, 19, 20, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 21, 22, 23, 24, 25],

    [1, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 19, 0, 0, 0, 0, 0, 25],
    [0, 0, 0, 0, 5, 0, 0, 0, 10, 0, 0, 0, 12, 0, 0, 0, 17, 0, 0, 0, 21, 0, 0, 0, 0],

    [1, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 21, 0, 0, 0, 25],
    [0, 0, 0, 0, 0, 0, 7, 0, 9, 0, 0, 0, 12, 0, 0, 0, 16, 0, 18, 0, 0, 0, 0, 0, 0],
  ];

  useEffect(() => {
      const tables = winningPatterns.map((pattern) => generateTable(pattern));
      setSlideshowTables(tables);
    }, []);

  const handleNumberSelect = (number) => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
    } else {
      setSelectedNumbers([...selectedNumbers, number]);
    }
  };

  const handleStartGame = async () => {
    setIsLoading(true);
    try {  
      const response = await axios.post(`${API}/game/start`, {
        bettingAmount: parseInt(bettingAmount),
        cardPaletteNumbers: selectedNumbers,
        callSpeed: parseInt(callSpeed),
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate('/game-in-progress', { state: { gameId: response.data.gameId, calledNumbers: response.data.calledNumbers, playingCards: response.data.playingCards, payoutToWinner: response.data.payoutToWinner, callSpeed, audioLanguage } });
    } catch (error) {
      console.error('Error starting the game:', error);
      console.log(error.response.data.message);
      if (error.response.status === 400 && error.response.data.message == 'Insufficient balance in wallet') {
        alert('Insufficient balance in wallet');
      }
    }finally {
      setIsLoading(false);
    }
  };

  const handleLocalStorage = () => {
      localStorage.setItem('bettingAmount', bettingAmount);
      localStorage.setItem('callSpeed', callSpeed);
      localStorage.setItem('audioLanguage', audioLanguage);
      localStorage.setItem('winningPattern', winningPattern);
    };
  
    const generateTable = (pattern) => {
      const cellSize = 25;
      const dotSize = 25;
    
      const cells = pattern.map((value, index) => (
        <td
          style={{
            padding: '15px 22px',
            position: 'relative',
            width: `25px`,
            height: `26px`,
            backgroundColor: '#999',
            border: '1px solid black',
          }}
        >
          <div
            key={index}
            className='text-center mx-auto'
            style={{
              width: `${dotSize}px`,
              height: `${dotSize}px`,
              backgroundColor: value === 12 ? '#a00' : value > 0 ? '#0c0' : 'transparent',
              borderRadius: '50%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          ></div>
        </td>
      ));
    
      const rows = [];
      for (let i = 0; i < 5; i++) {
        rows.push(
          <tr key={i} >
            {cells.slice(i * 5, (i + 1) * 5)}
          </tr>
        );
      }
    
      const headerCells = ['B', 'I', 'N', 'G', 'O'].map((letter, index) => (
        <th
          key={index}
          className='fw-bold text-center'
          style={{
            backgroundColor: ['#00D', '#d00', '#0dd', '#0d0', '#d0d'][index],
            width: `10px`,
            height: `10px`,
            fontSize: '50',
            border: '1px solid white',
          }}
        >
          {letter}
        </th>
      ));
    
      return (
        <table className='mb-0 pb-0' style={{backgroundColor: '#E2E8F0'}}>
          <thead>
            <tr>{headerCells}</tr>
          </thead>
          <tbody className='mb-0 pb-0' style={{backgroundColor: '#E2E8F0'}}>{rows}</tbody>
        </table>
      );
    };
  
  const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      arrows: false,
    };


  const ProfileIcon = ({ onClick }) => {
    return (
      <div className='d-flex'>
      <div
        style={{
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          // backgroundColor: '#ccc',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={onClick}
      >
        <h6 className='text-success'>Dashboard</h6>
      </div>
      <FaChevronDown className="mx-3 my-3" size={10} color="#fff" />
      </div>
    );
  };

  const handleProfileClick = () => {
      navigate('/reports')
    };
  

  return (
    <div style={{backgroundColor: "#000" }}>
      <div className="container-fluid pl-5 100vh" style={{ minHeight: "100vh", width: "95%", backgroundColor: "#000", marginLeft: '7%', marginRight: '2%', paddingTop: '2%' }}>
        <div className="row p-2">
          <div className="col-8 py-1 px-4" style={{ minHeight: "100vh", backgroundColor: "#000"}}>
            <div className="row pb-3 text-white">
            {/* First Column */}
            <div className="col-lg-12">
              <div className="row pb-1">
                <div className="col-6">
                  <label htmlFor="bettingAmount" className="form-label fw-bold">
                    Bet Amount
                  </label>
                  <input
                    list="bettingAmountOptions"
                    className="form-control text-white"
                    style={{backgroundColor: '#222', border: 'none', borderRadius: 0}}
                    value={bettingAmount}
                    onChange={(e) => setBettingAmount(e.target.value)}
                    placeholder="Select or type Bet Amount"
                    onBlur={handleLocalStorage}
                  />
                  <datalist id="bettingAmountOptions">
                    <option value="10"></option>
                    <option value="15"></option>
                    <option value="20"></option>
                    <option value="25"></option>
                    <option value="50"></option>
                    <option value="100"></option>
                    <option value="150"></option>
                    <option value="200"></option>
                  </datalist>
                </div>
                <div className="col-6">
                  <label htmlFor="payoutToWinner" className="form-label fw-bold">
                    Winning Pattern
                  </label>
                  <select
                    id="winningPattern"
                    className="form-select text-white"
                    style={{backgroundColor: '#222', border: 'none', borderRadius: 0}}
                    onBlur={handleLocalStorage}
                    value={winningPattern}
                    onChange={(e) => setWinningPattern(e.target.value)}
                  >
                    <option value="">select an option</option>
                    <option value="All">All Common Patterns</option>
                  </select>
                </div>
              </div>
            </div>
            {/* Second Column */}
            <div className="col-lg-12">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="audioLanguage" className="form-label fw-bold">
                    Audio Language
                  </label>
                  <select
                    id="audioLanguage"
                    className="form-select text-white"
                    value={audioLanguage}
                    onChange={(e) => setAudioLanguage(e.target.value)}
                    style={{backgroundColor: '#222', border: 'none', borderRadius: 0}}
                    onBlur={handleLocalStorage}
                  >
                    <option value="">select an option</option>
                    <option value="male">Amharic Male</option>
                    <option value="female">Amharic Female</option>
                    <option value="male">English Male</option>
                    <option value="female">English Female</option>
                    <option value="male">Tigrigna Male</option>
                    <option value="female">Tigrigna Female</option>
                  </select>
                </div>
                <div className="col-6">
                  <label htmlFor="callSpeed" className="form-label fw-bold">
                    Call Speed
                  </label>
                  <select
                    style={{backgroundColor: '#222', border: 'none', borderRadius: 0}}
                    id="callSpeed"
                    className="form-select text-white" 
                    value={callSpeed}
                    onChange={(e) => setCallSpeed(e.target.value)}
                    onBlur={handleLocalStorage}
                  >
                    <option value="">Select Call Speed</option>
                    <option value="5000">5 seconds</option>
                    <option value="6000">6 seconds</option>
                    <option value="7000">7 seconds</option>
                    <option value="10000">10 seconds</option>
                    <option value="15000">15 seconds</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="container m-0 p-0" style={{ width: '100%', maxHeight: "400px", overflowY: "scroll", border: '1px solid #666', borderColor: '#666', borderRadius: '5px', scrollbarWidth: 'thin', scrollbarColor: '#666 #141414' }}>
            {Array.from({ length: 10 }, (_, i) => (
              <div key={i} className="row m-0 p-0" style={{fontSize: 20}}>
                {Array.from({ length: 10 }, (_, j) => (
                  <div key={j} className="col-md-1 m-1 ">
                    <button
                      style={{fontSize: 25, color: '#225', fontWeight: 500}}
                      className={`btn m-1 ${
                        selectedNumbers.includes(i * 10 + j + 1) ? 'btn-warning text-white' : ''
                      }`}
                      onClick={() => handleNumberSelect(i * 10 + j + 1)}
                    >
                      {i * 10 + j + 1}
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="col-3 px-5 mx-auto d-flex flex-column">
          <div className="card" style={{marginBottom: '35%'}}>
            <div className="card-body p-0">
              <Slider {...settings}>
                {slideshowTables.map((table, index) => (
                  <div key={index}>{table}</div>
                ))}
              </Slider>
            </div>
          </div>
          <button
            className="btn btn-info btn-lg w-100"
            style={{marginTop: '90%'}}
            onClick={handleStartGame}
            disabled={!bettingAmount || !callSpeed || selectedNumbers.length < 2 || isLoading}
          >
            {isLoading ? 'Loading...' : 'Create Game'}
          </button>
        </div>
        <div className="col-1 mr-auto d-flex flex-row justify-content-end">
          <ProfileIcon onClick={handleProfileClick} className="text-end"/>
        </div>
      </div>
    </div>
    </div>
  );
};

export default DashboardPage;
